import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  component: Component,
  authenticated,
  hasAccess,
  role,
  ...rest
}) => {
  if (!authenticated && role === 0) return <Redirect to="/" />;

  const handleRedirect = () => {
    if (role === 1) return '/overview';
    if (role === 2 || role === 3 || role === 5) return '/dashboard';
    if (role === 4) return '/bookings/create';
    return '/';
  };

  return (
    <Route
      {...rest}
      render={props => (
        authenticated && hasAccess
          ? <Component {...props} />
          : (
            <Redirect to={handleRedirect()} />
          )
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  authenticated: PropTypes.bool.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  role: PropTypes.number.isRequired,
};

export default PrivateRoute;
