import React from 'react';
import PropTypes from 'prop-types';

const CCIcon = ({
  children, size, color, ...rest
}) => (
  <i
    {...rest}
    className={`cc-${children} ${rest.className || ''}`}
    style={{
      ...rest.style,
      color,
      fontSize: size,
    }}
  />
);

CCIcon.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

CCIcon.defaultProps = {
  size: 20,
  color: '#212121',
};

export default CCIcon;
