/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import '../assets/sass/components/dropdown.sass';
import CCIcon from './CCIcon';

const CCDropdown = ({
  options,
  position,
  hideIcon,
  transparent,
  type,
  activeItem,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(() => {
    if (isOpen) setIsOpen(false);
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <div
      className="dropdown"
      style={{
        zIndex: isOpen ? 999999 : 2,
      }}
    >
      <button
        type="button"
        className={`btn-dropdown ${transparent ? 'btn-dropdown--transparent' : ''} ${type ? `btn-${type}` : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {rest.children}
        {hideIcon ? null : (<i className="material-icons">arrow_drop_down</i>)}
      </button>
      {
        isOpen
          ? (
            <div
              className={`dropdown-options dropdown-position-${position}`}
              {...rest}
            >
              {
                options.map(({
                  divider, label, elem, ...itemRest
                }) => (
                  <div
                    key={uuidv4()}
                    {...itemRest}
                    onClick={() => itemRest.onClick(activeItem || {})}
                    className={`flex flex-jc--space-between dropdown--item ${divider ? 'dropdown--item-divider' : ''} ${itemRest.className || ''}`}
                  >
                    {
                      label || elem
                    }
                    {
                      itemRest.active && (
                      <CCIcon
                        color="#ff7900"
                      >
                        check-on
                      </CCIcon>
                      )
                    }
                  </div>
                ))
              }
            </div>
          )
          : null
      }
    </div>
  );
};

CCDropdown.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  position: PropTypes.string.isRequired,
  hideIcon: PropTypes.bool,
  transparent: PropTypes.bool,
  type: PropTypes.string,
  activeItem: PropTypes.instanceOf(Object),
};

CCDropdown.defaultProps = {
  hideIcon: false,
  transparent: true,
  type: '',
  activeItem: {},
};

export default CCDropdown;
