export const standard = [
  {
    name: 'bookings',
    width: 'half',
  },
  {
    name: 'contacts',
    width: 'half',
  },
  {
    name: 'wave-graph',
    width: 'full',
  },
  {
    name: 'pie-chart-1',
    width: 'half',
  },
  {
    name: 'pie-chart-2',
    width: 'half',
  },
  {
    name: 'map',
    width: 'half',
  },
  {
    name: 'environment',
    width: 'half',
  },
  {
    name: 'booking-list',
    width: 'full',
  },
];

export const booking = [
  {
    name: 'bookings',
    width: 'half',
  },
  {
    name: 'contacts',
    width: 'half',
  },
  {
    name: 'booking-list',
    width: 'full',
  },
  {
    name: 'map',
    width: 'half',
  },
  {
    name: 'pie-chart-1',
    width: 'half',
  },
  {
    name: 'wave-graph',
    width: 'full',
  },
];

export const economical = [
  {
    name: 'wave-graph',
    width: 'full',
  },
  {
    name: 'pie-chart-1',
    width: 'half',
  },
  {
    name: 'pie-chart-2',
    width: 'half',
  },
  {
    name: 'bookings',
    width: 'half',
  },
  {
    name: 'map',
    width: 'half',
  },
  {
    name: 'booking-list',
    width: 'full',
  },
  {
    name: 'environment',
    width: 'half',
  },
  {
    name: 'contacts',
    width: 'half',
  },
];
