import React from 'react';
import uuidv4 from 'uuid/v4';
import { CancelToken } from 'axios';

export const getQueryParam = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const apiDate = (str) => {
  const d = new Date(str);
  let month = d.getMonth() + 1;
  let date = d.getDate();

  if (month < 10) month = `0${month}`;
  if (date < 10) date = `0${date}`;

  return `${d.getFullYear()}-${month}-${date}`;
};

export const CMRLabelDate = (str) => {
  const d = new Date(str);
  let month = d.getMonth() + 1;
  let date = d.getDate();

  if (month < 10) month = `0${month}`;
  if (date < 10) date = `0${date}`;

  return `${d.getFullYear()}${month}${date}`;
};

export const cancelRequests = (source) => () => {
  Object.keys(source).forEach(current => {
    if (source[current]) source[current].cancel();
  });
};

export const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

// title - message - status
export const createNotification = (dispatch, data) => {
  const payload = {
    ...data,
    id: uuidv4(),
  };

  dispatch({
    payload,
    type: 'ADD',
  });

  setTimeout(() => {
    dispatch({
      payload,
      type: 'REMOVE',
    });
  }, 3000);
};

const capitalize = str => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const camelize = str => {
  const string = str.toLowerCase().replace(/[^A-Za-z0-9]/g, ' ').split(' ')
    .reduce((result, word) => result + capitalize(word.toLowerCase()));
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const createSourceToken = () => CancelToken.source();
export const sourceCreator = (params = {}) => ({
  get: createSourceToken(),
  post: createSourceToken(),
  update: createSourceToken(),
  delete: createSourceToken(),
  ...params,
});

export const createFormInput = (label, key, form, setForm, type = 'text') => (
  <div
    className="input-group input-group--big-margin"
    key={uuidv4()}
  >
    <label htmlFor={key}>{label}</label>
    <input
      type={type}
      id={key}
      placeholder={`Enter ${label}`}
      value={form[key]}
      onChange={(e) => setForm({ ...form, [key]: e.target.value })}
    />
  </div>
);

const renderNumberByTwo = (num) => {
  if (num < 10) return `0${num}`;
  return num;
};

export const parseDate = (date) => {
  const d = new Date(date);

  return `${d.getFullYear()}-${renderNumberByTwo(d.getMonth() + 1)}-${renderNumberByTwo(d.getDate())}`;
};

