import { lazy } from 'react';

const Components = lazy(() => import('../pages/Components'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const AccountSettings = lazy(() => import('../pages/AccountSettings'));
const Companies = lazy(() => import('../pages/admin/Companies'));
const Employees = lazy(() => import('../pages/admin/Employees'));
const Employee = lazy(() => import('../pages/admin/Employee'));
const Company = lazy(() => import('../pages/admin/Company'));
const Overview = lazy(() => import('../pages/admin/Overview'));
const Users = lazy(() => import('../pages/admin/Users'));
const Tips = lazy(() => import('../pages/admin/Tips'));
const Booking = lazy(() => import('../pages/Booking'));
const Bookings = lazy(() => import('../pages/Bookings'));
const Invoices = lazy(() => import('../pages/Invoices'));
const NewBookings = lazy(() => import('../pages/NewBookings'));
const Reports = lazy(() => import('../pages/Reports'));
const PriceRequest = lazy(() => import('../pages/PriceRequest'));
const CreatedBookings = lazy(() => import('../pages/CreatedBookings'));
const CustomerService = lazy(() => import('../pages/CustomerService'));
const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const SignUp = lazy(() => import('../pages/SignUp'));
const AcceptedInvitation = lazy(() => import('../pages/AcceptedInvitation'));
const SearchResults = lazy(() => import('../pages/SearchResults'));

export default {
  notAuthenticated: [
    {
      component: Login,
      path: '/',
    },
    {
      component: ForgotPassword,
      path: '/forgot-password',
    },
    {
      component: ChangePassword,
      path: '/change-password',
    },
    {
      component: SignUp,
      path: '/sign-up',
    },
    {
      component: AcceptedInvitation,
      path: '/i/:key',
    },
  ],
  users: [
    {
      component: Dashboard,
      path: '/dashboard',
    },
    {
      component: Company,
      path: '/companies/:id',
      exact: true,
    },
    {
      component: AccountSettings,
      path: '/account-settings',
    },
    {
      component: Components,
      path: '/components',
    },
    {
      component: PriceRequest,
      path: '/bookings/price-request',
      exact: true,
    },
    {
      component: CreatedBookings,
      path: '/bookings/created-bookings',
      exact: true,
    },
    {
      component: NewBookings,
      path: '/bookings/create',
      exact: true,
    },
    {
      component: Bookings,
      path: '/bookings/invoices/:invoiceId',
      exact: true,
    },
    {
      component: Booking,
      path: '/bookings/:id',
      exact: true,
    },
    {
      component: Bookings,
      path: '/bookings',
      exact: true,
    },
    {
      component: Reports,
      path: '/reports',
    },
    {
      component: Invoices,
      path: '/invoices',
    },
    {
      component: CustomerService,
      path: '/customer-service',
    },
    {
      component: CustomerService,
      path: '/customer-service',
    },
    {
      component: SearchResults,
      path: '/search-results',
    },
  ],
  admin: [
    {
      component: Overview,
      path: '/overview',
    },
    {
      component: Company,
      path: '/companies/:id',
      exact: true,
    },
    {
      component: Companies,
      path: '/companies',
      exact: true,
    },
    {
      component: Users,
      path: '/users',
    },
    {
      component: Tips,
      path: '/tips',
    },
    {
      component: Employee,
      path: '/employees/:id',
    },
    {
      component: Employees,
      path: '/employees',
    },
  ],
  bookingShipmentRole: [
    {
      component: CreatedBookings,
      path: '/bookings/created-bookings',
      exact: true,
    },
    {
      component: NewBookings,
      path: '/bookings/create',
      exact: true,
    },
    {
      component: AccountSettings,
      path: '/account-settings',
    },
    {
      component: CustomerService,
      path: '/customer-service',
    },
  ],
  bookingRole: [
    {
      component: Dashboard,
      path: '/dashboard',
    },
    {
      component: Company,
      path: '/companies/:id',
      exact: true,
    },
    {
      component: AccountSettings,
      path: '/account-settings',
    },
    {
      component: Components,
      path: '/components',
    },
    {
      component: PriceRequest,
      path: '/bookings/price-request',
      exact: true,
    },
    {
      component: CreatedBookings,
      path: '/bookings/created-bookings',
      exact: true,
    },
    {
      component: NewBookings,
      path: '/bookings/create',
      exact: true,
    },
    {
      component: Bookings,
      path: '/bookings/invoices/:invoiceId',
      exact: true,
    },
    {
      component: Booking,
      path: '/bookings/:id',
      exact: true,
    },
    {
      component: Bookings,
      path: '/bookings',
      exact: true,
    },
    {
      component: Reports,
      path: '/reports',
    },
    {
      component: CustomerService,
      path: '/customer-service',
    },
    {
      component: CustomerService,
      path: '/customer-service',
    },
    {
      component: SearchResults,
      path: '/search-results',
    },
  ],
};
