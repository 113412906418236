import helper from '../helpers/api';
import api from '../configs/integration';

const resource = 'bookings';
const params = {
  fields: 'id,dbid,order_id,trip_csv,trip_dbcsv,mode,from_place,from_name,from_address1,from_country,from_zipcode,to_place,to_name,to_address1,to_country,to_zipcode,status,etd,eta,ata,atd,total_kg,total_m3,invoice_csv,departure_status,total_kg_freight,total_lm,total_m3,_changes,order_date,total_packages,org_unit,arrival_status,status_full,order_ref,loading_ref,unloading_ref,containers_id_csv,hawb_id,containers_teu,total_invoice_lcy,lcy_currency,pol,pod,containers_type_csv,containers,customer_id,original_received,loading_name,loading_address1,loading_zipcode,loading_place,loading_country,unloading_name,unloading_address1,unloading_zipcode,unloading_place,unloading_country,po_reason_to,po_status_to,invoice_dbcsv,service_code,vessel,order_items,projectreference',
};

export default {
  ...helper(resource, 'integration', params),
  export: (exportType, fields, filter, sort, querySearch) => api.get(`${resource}?export=${exportType}&fields=${fields}&${filter}${sort && sort.length ? `&sort=${sort[0].key}:${sort[0].mode}` : ''}${querySearch && querySearch.length ? `&q=${querySearch}` : ''}`),
  addAttachment: formData => api.post('upload-file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
};
