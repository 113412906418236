/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import '../assets/sass/components/nav.sass';
import { NavLink } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import apiFactory from '../factories/api';
import {
  admin, user, bookingShipmentRole, bookingRole,
} from '../constants/navigation';
import CCIcon from './CCIcon';
import events from '../helpers/events';
import contextFactory from '../factories/context';

const companyAPI = apiFactory.get('company');
const { useStateValue } = contextFactory.get('auth');

const CCNav = ({ role }) => {
  const [{ details: authDetails }] = useStateValue();
  const [shrink, setShrink] = useState(false);
  const [storageCredential, setStorageCredential] = useState({});
  const [domesticCredential, setDomesticCredential] = useState({});
  const [internationalCredential, setInternationCredential] = useState({});
  const [accounts, setAccounts] = useState([]);
  const handleScroll = useCallback(
    () => {
      if (window.pageYOffset > 80) {
        if (!shrink) {
          setShrink(true);
        }
      } else if (window.pageYOffset < 40) {
        setShrink(false);
      }
    },
    [shrink],
  );

  const defaultShowLoginState = {
    storage: false,
    domestic: false,
    international: false,
  };

  const [showLogins, setShowLogins] = useState(defaultShowLoginState);

  const getAccounts = async () => {
    try {
      setShowLogins(defaultShowLoginState);
      const { data } = await companyAPI.getAccounts();
      data.forEach(item => {
        setShowLogins(currentState => ({
          ...currentState,
          [item.type]: !!item.show,
        }));
      });
      const filteredStorage = data.filter(item => item.company_id === authDetails.active_company_id && item.type === 'storage');
      const filteredDomestic = data.filter(item => item.company_id === authDetails.active_company_id && item.type === 'domestic');
      const filteredInternational = data.filter(item => item.company_id === authDetails.active_company_id && item.type === 'international');
      setAccounts(data);
      setStorageCredential(filteredStorage[filteredStorage.length - 1] || {});
      setDomesticCredential(filteredDomestic[filteredDomestic.length - 1] || {});
      setInternationCredential(filteredInternational[filteredInternational.length - 1] || {});
    } catch (error) {
      console.log(error);
    }
  };

  const handleList = () => {
    if (role === 1) return admin;
    if (role === 2 || role === 3) return user;
    if (role === 4) return bookingShipmentRole;
    if (role === 5) return bookingRole;
    return [];
  };

  const internationalSingleSignOn = () => {
    const params = {
      brukerid: internationalCredential.username || '',
      passord: internationalCredential.password || '',
    };
    const form = document.createElement('form');
    form.method = 'get';
    form.action = 'http://ecare.collicare.no/sycgip/symn0.pgm';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const field = document.createElement('input');
        field.type = 'hidden';
        field.name = key;
        field.value = params[key];

        form.appendChild(field);
      }
    }
    document.body.appendChild(form);
    form.submit();
  };

  const domesticSingleSignOn = () => {
    const params = {
      username: domesticCredential.username || '',
      password: domesticCredential.password || '',
    };
    const form = document.createElement('form');
    form.method = 'post';
    form.action = domesticCredential.country === 'sweden' ? 'https://order.collicare.se/ccno/opter/Account/ExternalLogin' : 'https://order.collicare.no/ccno/opter/Account/ExternalLogin';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const field = document.createElement('input');
        field.type = 'hidden';
        field.name = key;
        field.value = params[key];
        form.appendChild(field);
      }
    }
    document.body.appendChild(form);
    // console.log(form);
    form.submit();
  };
  const storageSingleSignOn = () => {
    const params = {
      __VIEWSTATE: '/wEPDwUJNzA2NjM3OTc1DxYCHhNWYWxpZGF0ZVJlcXVlc3RNb2RlAgEWAgIBD2QWBgILDxBkDxYBZhYBEAUWTllDRS5MT0dJQyBXb3JrIFNlcnZlcgVCdGNw0ILCo8WTwrNDT0xBU3YwMDnQgsKjxZPCszQwMDHQgsKjxZPCs1dvcmtTZXJ2ZXJVUknQgsKjxZPCs0ZhbHNlZ2RkAhMPEGQQFQQHRW5nbGlzaAdTdmVuc2thDtCg0YPRgdGB0LrQuNC5BU5vcnNrFQQDRU5HA1NXRQNSVVMDTk9SFCsDBGdnZ2dkZAIVDxYCHgdWaXNpYmxlaGQYAQUeX19Db250cm9sc1JlcXVpcmVQb3N0QmFja0tleV9fFgIFDmNiVXNlckxhbmd1YWdlBQhidG5Mb2dvbsXng3wdmyJsbUVtnSkI1V2R80v/1gkGkhf0kUClvWa1',
      tbLogin: storageCredential.username || '',
      tbPwd: storageCredential.password || '',
      ddlServer: 'tcpЂ£œ³COLASv009Ђ£œ³4001Ђ£œ³WorkServerURIЂ£œ³False',
      ddlLanguage: 'ENG',
      'btnLogon.x': '46',
      'btnLogon.y': '12',
      hfUserSetting: '',
    };
    const form = document.createElement('form');
    form.method = 'post';
    // need to put back the old url, jira card CE-12
    form.action = 'https://prodwms.collicare.com/';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const field = document.createElement('input');
        field.type = 'hidden';
        field.name = key;
        field.value = params[key];

        form.appendChild(field);
      }
    }
    document.body.appendChild(form);
    form.submit();
  };
  useEffect(() => {
    if (authDetails) {
      events.$on('nav--external-loggedin', () => {
        const filteredStorage = accounts.filter(item => item.company_id === authDetails.active_company_id && item.type === 'storage');
        const filteredDomestic = accounts.filter(item => item.company_id === authDetails.active_company_id && item.type === 'domestic');
        const filteredInternational = accounts.filter(item => item.company_id === authDetails.active_company_id && item.type === 'international');
        getAccounts();
        setStorageCredential(filteredStorage[filteredStorage.length - 1] || {});
        setDomesticCredential(filteredDomestic[filteredDomestic.length - 1] || {});
        setInternationCredential(filteredInternational[filteredInternational.length - 1] || {});
      });
    }
    return () => {
      events.$off('nav--external-loggedin');
    };
  }, [authDetails]);

  useEffect(() => {
    getAccounts();
    window.addEventListener('scroll', handleScroll);

    events.$on('nav--external-login', () => {
      getAccounts();
    });
    events.$on('nav--switch-company', () => {
      getAccounts();
    });
    return () => {
      events.$off('nav--external-login');
      events.$off('nav--switch-company');

      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navigation-container ${shrink ? 'navigation-container--shrink' : ''}`}>
      <div className="container">
        <ul className="link-container">
          {
            (handleList()).map(({
              icon, label, path, disabled,
            }) => (
              <li
                className={disabled ? 'link-lists-disabled' : 'link-lists'}
                style={{
                  width: '100%',
                }}
                key={uuidv4()}
              >
                <NavLink
                  to={path}
                  activeClassName="link-active"
                  onClick={e => {
                    if (disabled) e.preventDefault();
                  }}
                >
                  <div className="image-icon">
                    <CCIcon size={40} color={disabled ? '#75746D' : 'white'}>{icon}</CCIcon>
                  </div>
                  <p>{label}</p>
                </NavLink>
              </li>
            ))
          }
        </ul>
        {
          authDetails && authDetails.role !== 4 ? (
            <ul className="link-list">
              {showLogins.storage && (
                <li className="link-list-button">
                  <span
                    onClick={() => storageSingleSignOn()}
                    style={{ marginLeft: 0 }}
                    className="flex flex-ai--center flex-jc--space-between"
                  >
                    Storage
                    <i className="cc-external" />
                  </span>
                </li>
              )}
              {showLogins.domestic && (
                <li className="link-list-button">
                  {/* <a
                  href="https://order.collicare.no/ccno/opter/Account/ExternalLogin"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-ai--center flex-jc--space-between"
                >
                Domestic
                  <i className="cc-external" />
                </a> */}
                  <span
                    style={{ marginLeft: 0 }}
                    onClick={() => domesticSingleSignOn()}
                    className="flex flex-ai--center flex-jc--space-between"
                  >
                    Domestic
                    <i className="cc-external" />
                  </span>
                </li>
              )}
              {showLogins.international && (
                <li className="link-list-button">
                  <span
                    style={{ marginLeft: 0 }}
                    onClick={() => internationalSingleSignOn()}
                    className="flex flex-ai--center flex-jc--space-between"
                  >
                    International
                    <i className="cc-external" />
                  </span>
                </li>
              )}
            </ul>
          ) : null
        }
      </div>
    </nav>
  );
};

CCNav.propTypes = {
  role: PropTypes.number.isRequired,
};

export default CCNav;
