export default (state, action) => {
  switch (action.type) {
    case 'ADD': {
      return [
        action.payload,
        ...state,
      ];
    }

    case 'REMOVE': {
      return state.filter(item => item.id !== action.payload.id);
    }

    default:
      return state;
  }
};
