import * as auth from '../contexts/Auth';
import * as notifications from '../contexts/Notifications';
import * as view from '../contexts/View';
import * as fileBooking from '../contexts/FileBooking';
import * as graph from '../contexts/Graph';

const factories = {
  auth,
  notifications,
  view,
  fileBooking,
  graph,
};

export default {
  get: (key) => factories[key],
};
