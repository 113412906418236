import api from '../configs/api';
import helper from '../helpers/api';

const resource = 'company';

export default {
  ...helper(resource),
  getOneCompany: (id) => api.get(`${resource}/${id}`),
  addEmployee: id => api.post(`${resource}/${id}/employee`),
  changeDetails: (id, form) => api.put(`${resource}/${id}`, form),
  inviteUser: (payload) => api.post(`${resource}/${payload.companyId}/invite-user`, payload),
  removeUser: (id, payload = null) => api.delete(`${resource}/user/${id}`, {
    data: (payload || {}),
  }),
  getTeamMembers: id => api.get(`${resource}/${id}/team-members`),
  getEmployeesAssignedToCompany: id => api.get(`${resource}/${id}/employee`),
  assignEmployeesToCompany: (id, data) => api.post(`${resource}/${id}/employee`, data),
  getAddresses: (id, headers = {}) => api.get(`${resource}/${id}/address`, headers),
  getAgreements: () => api.get(`${resource}/agreements`),
  getIntegrations: (id, headers = {}) => api.get(`${resource}/${id}/integration`, headers),
  addAvatar: (id, formData) => api.post(`avatar?type=${resource}&id=${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  removeEmployee: (companyId, employeeId) => api.delete(`${resource}/${companyId}/employee/${employeeId}`),
  addAccounts: (payload) => api.post(`${resource}/accounts`, payload),
  updateAccount: (payload) => api.put(`${resource}/accounts/${payload.id}`, payload),
  getAccounts: () => api.get(`${resource}/accounts`),
  removeUserFromCompany: (companyId, employeeId, removeType) => api.delete(`${resource}/${companyId}/team-members/${employeeId}?type=${removeType}`),
};
