/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { get as optional, isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import CCDropdown from './CCDropdown';
import { useStateValue } from '../contexts/Auth';
import apiFactory from '../factories/api';
import '../assets/sass/components/header.sass';
import { getUserActiveCompany } from '../helpers/getUserActiveCompany';
import events from '../helpers/events';

const companyAPI = apiFactory.get('company');
const authAPI = apiFactory.get('auth');

const CCHeader = withRouter(({ history, name, role }) => {
  // eslint-disable-next-line no-empty-pattern
  const [search, setSearch] = useState('');
  const [{ details }, dispatch] = useStateValue();
  const [showNotifications, setShowNotifications] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [userCompanies, setUserCompanies] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [activeCompanyDetails, setActiveCompanyDetails] = useState({});
  const [trackNo, setTrackNo] = useState('');
  useEffect(() => {
    if (details) {
      setActiveCompanyDetails(getUserActiveCompany(details).activeCompany);
      setUserCompanies(getUserActiveCompany(details).userCompanies.map(item => {
        item.active = details.active_company_id === item.id;
        return item;
      }));
    }
  }, [details]);

  const getUserRole = () => {
    const userRole = details.active_company ? details.active_company.pivot.role === 2 : details.role === 2;
    return userRole;
  };

  const getRoleName = (userRole) => {
    if (role === 1) return 'System Admin';
    if (userRole === 2) return 'Company Admin';
    if (userRole === 3) return 'User';
    if (userRole === 4) return 'Booking Shipment';
    if (userRole === 5) return 'Booking';
    return '';
  };

  const globalSearch = (e) => {
    e.preventDefault();
    if (search !== '') {
      if (window.location.href.includes('invoices')) {
        history.push(`/invoices?q=${encodeURIComponent(search)}`);
        window.location.href = `/invoices?q=${encodeURIComponent(search)}`;
      } else {
        history.push(`/bookings?q=${encodeURIComponent(search)}`);
        window.location.href = `/bookings?q=${encodeURIComponent(search)}`;
      }
    }
  };

  const trackAndTrace = (e) => {
    e.preventDefault();
    const formattedTrackNo = trackNo.includes('/') ? trackNo.replace(/\//g, '__') : trackNo;
    const activeDomain = activeCompanyDetails && activeCompanyDetails.domain
      ? activeCompanyDetails.domain
      : 'https://www.collicare.no/track-and-trace';
    if (trackNo !== '') window.open(`${activeDomain}/#/s/${formattedTrackNo}?key=pjE2WJCcYfLp&integrationIds=${integrations ? integrations.join(',') : ''}`);
    // if (trackNo !== '') window.open(`https://staging-collicare-tnt-v2-z6e6thqb4q-ew.a.run.app/#/s/${formattedTrackNo}?key=test&integrationIds=${details.active_company.integration}`);
  };

  const logout = () => {
    dispatch({
      type: 'REMOVE_DETAILS',
    });
    history.push('/');
  };

  const getCompanyDetails = async () => {
    try {
      // const { data } = await companyAPI.getOneCompany(details && details.active_company_id ? details.active_company_id : details.company_id);
      const { data } = await companyAPI.getOneCompany(details.active_company_id || details.company_id);
      setCompanyDetails(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyIntegration = async () => {
    try {
      const { data } = await companyAPI.getIntegrations(details.active_company.id);
      const integrationIds = data.map(item => {
        const obj = `${item.system}_${item.org_unit}_${item.customer_id}`;
        return obj;
      });
      setIntegrations(integrationIds);
    } catch (error) {
      console.log(error);
    }
  };

  const switchCompany = async (id) => {
    try {
      const { data } = await authAPI.switchCompany({ company_id: id });
      dispatch({
        type: 'CHANGE_DETAILS',
        payload: {
          active_company: data.active_company,
          avatar: data.active_company.avatar,
          active_company_id: data.active_company.id || null,
          integration_token: data.integration_token,
        },
      });
      events.$emit('nav--switch-company');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (details && details.id && details.company_id) {
      getCompanyDetails();
    }
  }, [details.id]);

  useEffect(() => {
    if (details && details.active_company && details.active_company_id) {
      getCompanyIntegration();
    }
  }, [details.active_company_id]);

  return (
    <header className="header-container">
      <div className="container">
        <div className="left-container">
          <img
            className="company-logo"
            src={`${process.env.PUBLIC_URL}/images/collicare-logo.svg`}
            alt="company-logo"
          />
          <form
            className="input-group"
            onSubmit={globalSearch}
          >
            <i className="material-icons">search</i>
            <input

              type="text"
              placeholder="Search here for reports, analytics and help"
              value={search}
              onChange={({ target }) => setSearch(target.value)}
            />
          </form>
          <form
            className="input-group input-group--no-border input-group--no-margin"
            method="post"
            target="_blank"
            onSubmit={trackAndTrace}
          >
            <i className="material-icons">search</i>
            <input
              onChange={({ target }) => setTrackNo(target.value)}
              type="text"
              name="colli-keyword"
              placeholder="Track and trace shipment here"
            />
            <button type="submit" />
          </form>
        </div>
        <div className="right-container">
          <CSSTransition
            in={showNotifications}
            timeout={150}
            unmountOnExit
            classNames="notification-anim"
          >
            <ul className="notifications">
              <li className="notifications-item">
                <i className="material-icons" style={{ color: 'red' }}>warning</i>
                <div className="right">
                  <p className="title">Delayed</p>
                  <p className="content">Shipment 4936482 is being delayed. Click to read more</p>
                </div>
              </li>
              <li className="notifications-item">
                <i className="material-icons" style={{ color: '#4eda4e' }}>check</i>
                <div className="right">
                  <p className="title">Delivered</p>
                  <p className="content">Package 4936482 has been delivered on time.</p>
                </div>
              </li>
              <li className="notifications-item">
                <i className="material-icons">message</i>
                <div className="right">
                  <p className="title">Hello</p>
                  <p className="content">Our client sent you a response in regards of #123134</p>
                </div>
                <div className="actions">
                  <button
                    className="orange"
                    type="button"
                    onClick={() => alert('reply function')}
                  >Reply
                  </button>
                  <button
                    type="button"
                    onClick={() => alert('dismiss function')}
                  >Dismiss
                  </button>
                </div>
              </li>
            </ul>
          </CSSTransition>
          {/* <button
            className="notification-container"
            type="button"
            onClick={() => setShowNotifications(!showNotifications)}
          >
            <i className="material-icons">notifications</i>
            <span>3</span>
          </button> */}
          <CCDropdown
            style={{
              top: '50px',
            }}
            position="right"
            options={
              details.role === 1
                ? [
                  {
                    label: 'Logout',
                    onClick: () => logout(),
                  },
                ]
                : getUserRole()
                  ? [
                    {
                      label: 'My Profile',
                      onClick: () => history.push('/account-settings'),
                      divider: true,
                    },
                    // {
                    //   label: 'Company Admin',
                    //   onClick: () => history.push(`/companies/${details && details.active_company_id ? details.active_company_id : details.company_id}`),
                    //   divider: true,
                    // },
                    ...userCompanies.map(item => {
                      const obj = {
                        label: item.name,
                        divider: true,
                        active: item.active,
                        onClick: () => switchCompany(item.id),
                      };
                      return obj;
                    }),
                    {
                      label: 'Logout',
                      onClick: () => logout(),
                    },
                  ] : [
                    {
                      label: 'My Profile',
                      onClick: () => history.push('/account-settings'),
                      divider: true,
                    },
                    ...userCompanies.map(item => {
                      const obj = {
                        label: item.name,
                        divider: true,
                        active: item.active,
                        onClick: () => switchCompany(item.id),
                      };
                      return obj;
                    }),
                    {
                      label: 'Logout',
                      onClick: () => logout(),
                    },
                  ]
            }
          >
            <div className="details-user-content">
              <p className="details-user--name">{`${name || ''} ${optional(details, 'active_company_id') ? `(${optional(activeCompanyDetails, 'name') || ''})` : ''}`}</p>
              <p className="details-user--role">{getRoleName(!isEmpty(optional(activeCompanyDetails, 'pivot')) ? optional(activeCompanyDetails, 'pivot.role') : '')}</p>
            </div>
          </CCDropdown>
          {
            details.avatar
              ? (
                <div
                  className="header-avatar"
                  style={{
                    background: `url(${details.avatar})`,
                    height: 70,
                    width: 70,
                  }}
                />
              )
              : null
          }
        </div>
      </div>
    </header>
  );
});

CCHeader.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.number.isRequired,
};

export default CCHeader;
