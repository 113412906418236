import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const ViewContext = createContext();

export const ViewProvider = ({ initialState, reducer, children }) => (
  <ViewContext.Provider value={useReducer(reducer, initialState)}>
    { children }
  </ViewContext.Provider>
);

ViewProvider.propTypes = {
  initialState: PropTypes.instanceOf(Object).isRequired,
  reducer: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useStateValue = () => useContext(ViewContext);
