import React, { useState } from 'react';
import '../../assets/sass/base.sass';
// import CCNotification from '../../components/CCNotification';
import Router from './Router';
import { details } from '../../constants/localStorageKey';
import contextFactory from '../../factories/context';
import reducerFactory from '../../factories/reducer';

const { AuthProvider } = contextFactory.get('auth');
const { NotificationsProvider } = contextFactory.get('notifications');
const authReducer = reducerFactory.get('auth');
const notificationsReducer = reducerFactory.get('notifications');
const { GraphProvider } = contextFactory.get('graph');
const graphReducer = reducerFactory.get('graph');

const App = () => {
  const [authInitialState] = useState(
    localStorage[details]
      ? JSON.parse(localStorage[details])
      : ({
        authenticated: false,
        details: {},
      }),
  );

  let today = new Date();
  let toDate = new Date();
  toDate.setDate(today.getDate() + 7);
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const toDd = toDate.getDate();
  const toMm = toDate.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  today = `${yyyy}/${mm}/${dd}`;
  toDate = `${yyyy}/${toMm}/${toDd}`;

  const graphInitialState = {
    form: {
      type: 'quantity',
      typeLabel: 'Quantity',
      mode: 'all',
      quantityLabel: 'Actual KG',
      quantityType: 'total_kg_sum',
      invoiceLabel: 'NOK',
      invoiceType: 'total_invoice_nok_sum',
      deliveryLabel: 'Actual KG',
      deliveryType: 'eta_total_kg_sum',
      fromDate: null,
      toDate: null,
    },
  };

  return (
    <AuthProvider
      initialState={authInitialState}
      reducer={authReducer}
    >
      <NotificationsProvider reducer={notificationsReducer}>
        {/* <CCNotification /> */}
        <GraphProvider
          initialState={graphInitialState}
          reducer={graphReducer}
        >
          <Router />
        </GraphProvider>
      </NotificationsProvider>
    </AuthProvider>
  );
};

export default App;
