import api from '../configs/api';
import helper from '../helpers/api';

const resource = 'setting';

export default {
  ...helper(resource),
  getByType: (payload, type = 'filter', headers = {}) => api.get(`${resource}/${payload}/${type}`, headers),
  subscribeToReport: data => api.post(`${resource}/subscribe`, data),
  updateReport: (id, data) => api.put(`${resource}/${id}`, data),
};
