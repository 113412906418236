import api from '../configs/api';
import helper from '../helpers/api';

const resource = 'user';

export default {
  ...helper(resource),
  acceptInvitation: payload => api.post(`${resource}/accepted-invitation`, payload),
  resendInvitation: id => api.post(`${resource}/resend-invitation/${id}`),
  verifyIfUserExists: id => api.get(`${resource}/${id}/verify-user-exists`),
  changeDetails: payload => api.put(`${resource}/${payload.id}/change-details`, payload),
  initialData: () => api.get(`${resource}/initial-data`),
  deleteInvitedUser: (payload) => api.delete(
    `${resource}/${payload.id}`,
    { data: payload },
  ),
  addAvatar: (id, formData) => api.post(`avatar?type=${resource}&id=${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  changeViewDetails: (id, payload) => api.put(`${resource}/${id}/change-user-view`, payload),
  validateInvitation: (key) => api.post(`${resource}/validate-invitation`, key),
  bookingTimeStamp: () => api.get(`${resource}/booking-changes-timestamp`),
};
