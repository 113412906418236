export const admin = [
  {
    icon: 'dashboard',
    label: 'Overview',
    path: '/overview',
    disabled: false,
  },
  {
    icon: 'companies',
    label: 'Companies',
    path: '/companies',
    disabled: false,
  },
  {
    icon: 'customer-service',
    label: 'Users',
    path: '/users',
    disabled: false,
  },
  {
    icon: 'tips',
    label: 'Tips',
    path: '/tips',
    disabled: false,
  },
  {
    icon: 'employees',
    label: 'Employees',
    path: '/employees',
    disabled: false,
  },
];

export const user = [
  {
    icon: 'collicare1',
    label: 'Dashboard',
    path: '/dashboard',
    disabled: false,
  },
  {
    icon: 'collicare4',
    label: 'Bookings',
    path: '/bookings',
    disabled: false,
  },
  {
    icon: 'collicare2',
    label: 'Reports',
    path: '/reports',
    disabled: false,
  },
  {
    icon: 'collicare3',
    label: 'Invoice',
    path: '/invoices',
    disabled: false,
  },
  {
    icon: 'collicare5',
    label: 'Customer Service',
    path: '/customer-service',
    disabled: false,
  },
];

export const bookingShipmentRole = [
  {
    icon: 'collicare1',
    label: 'Dashboard',
    path: '/dashboard',
    disabled: true,
  },
  {
    icon: 'collicare4',
    label: 'Bookings',
    path: '/bookings/create',
    disabled: false,
  },
  {
    icon: 'collicare2',
    label: 'Reports',
    path: '/reports',
    disabled: true,
  },
  {
    icon: 'collicare3',
    label: 'Invoice',
    path: '/invoices',
    disabled: true,
  },
  {
    icon: 'collicare5',
    label: 'Customer Service',
    path: '/customer-service',
    disabled: false,
  },
];

export const bookingRole = [
  {
    icon: 'collicare1',
    label: 'Dashboard',
    path: '/dashboard',
    disabled: false,
  },
  {
    icon: 'collicare4',
    label: 'Bookings',
    path: '/bookings',
    disabled: false,
  },
  {
    icon: 'collicare2',
    label: 'Reports',
    path: '/reports',
    disabled: false,
  },
  {
    icon: 'collicare3',
    label: 'Invoice',
    path: '/invoices',
    disabled: true,
  },
  {
    icon: 'collicare5',
    label: 'Customer Service',
    path: '/customer-service',
    disabled: false,
  },
];
