/* eslint-disable prefer-destructuring */

export const getUserActiveCompany = (authDetails) => {
  let userCompanies = [];
  let activeCompany = {};
  if (authDetails && (authDetails.active_company_id || authDetails.company_id)) {
    // userCompanies = [{ ...authDetails.company }];
    if (authDetails.companies) {
      userCompanies = [
        ...userCompanies,
        ...authDetails.companies,
      ];
    }
  }
  if (authDetails && authDetails.active_company_id) {
    activeCompany = userCompanies.filter(item => item.id === authDetails.active_company_id)[0];
  }
  if (userCompanies.length === 1 && !authDetails.active_company_id) {
    activeCompany = userCompanies[0];
  }
  return {
    userCompanies,
    activeCompany,
  };
};

export default getUserActiveCompany;
