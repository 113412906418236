import { details as detailsKey, view as viewKey } from '../../constants/localStorageKey';
import { standard } from '../../constants/views';

export default (state, action) => {
  switch (action.type) {
    case 'CHANGE_DETAILS': {
      const oldData = localStorage[detailsKey] ? JSON.parse(localStorage[detailsKey]).details : {};

      const data = {
        ...state,
        authenticated: true,
        details: {
          ...oldData,
          ...action.payload,
        },
      };

      localStorage[detailsKey] = JSON.stringify(data);
      localStorage[viewKey] = JSON.stringify(action.payload.view || standard);

      return data;
    }

    case 'REMOVE_DETAILS': {
      setTimeout(() => {
        localStorage.removeItem(detailsKey);
        localStorage.removeItem(viewKey);
      }, 500);

      return {
        authenticated: false,
        details: {},
      };
    }

    default:
      return state;
  }
};
