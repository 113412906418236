import api from '../configs/api';
import helper from '../helpers/api';

const resource = 'integration';

export default {
  ...helper(resource),
  changeDetails: (formData) => api.put(`${resource}/${formData.id}`, formData),
  getIntegration: id => api.get(`${resource}/${id}`),
};
