import helper from '../helpers/api';
import api from '../configs/api';

const resource = 'address';

export default {
  ...helper(resource),
  createAddress: data => api.post(`${resource}`, data),
  removeAddress: id => api.delete(`${resource}/${id}`),
  importAddresses: (id, formData) => api.post(`${resource}/import?company_id=${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/formdata',
    },
  }),
};
