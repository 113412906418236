import auth from '../api/auth';
import user from '../api/user';
import company from '../api/company';
import tip from '../api/tip';
import notification from '../api/notification';
import employee from '../api/employee';
import createdBookings from '../api/createdBookings';
import term from '../api/term';
import role from '../api/role';
import address from '../api/address';
import setting from '../api/setting';
import booking from '../api/booking';
import invoice from '../api/invoice';
import claim from '../api/claim';
import integration from '../api/integration';
import questionnaire from '../api/questionnaire';
import stat from '../api/stat';
import file from '../api/file';
import customerServiceInfo from '../api/customerServiceInfo';
import pack from '../api/pack';

const api = {
  auth,
  user,
  company,
  tip,
  notification,
  claim,
  employee,
  term,
  role,
  address,
  setting,
  integration,
  questionnaire,
  createdBookings,
  customerServiceInfo,
  pack,
};

const integrationFactory = {
  booking,
  invoice,
  claim,
  stat,
  file,
};

export default {
  get: (key) => api[key] || {},
  integrate: (key) => integrationFactory[key] || {},
};
