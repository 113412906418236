import auth from '../contexts/reducers/auth';
import notifications from '../contexts/reducers/notifications';
import view from '../contexts/reducers/view';
import fileBooking from '../contexts/reducers/fileBooking';
import graph from '../contexts/reducers/graph';

const factories = {
  auth,
  notifications,
  view,
  fileBooking,
  graph,
};

export default {
  get: (key) => factories[key],
};
