import api from '../configs/api';

const resource = 'auth';

export default {
  login: payload => api.post(`${resource}/login`, payload),
  register: payload => api.post(`${resource}/register`, payload),
  forgotPassword: payload => api.post(`${resource}/forgot-password`, payload),
  changePassword: payload => api.post(`${resource}/change-password`, payload),
  loginAsUser: id => api.post(`${resource}/login-as`, {
    user_id: id,
  }),
  switchCompany: payload => api.post(`${resource}/switch-company`, payload),
  sendEmail: payload => api.post(`${resource}/send-email`, payload),

};
