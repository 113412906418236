import helper from '../helpers/api';
import api from '../configs/api';

const resource = 'employee';

export default {
  ...helper(resource),
  addEmployee: id => api.post(`company/${id}/${resource}`),
  changeDetails: (id, form) => api.put(`${resource}/${id}`, form),
  getCompaniesConnectedToEmployee: id => api.get(`${resource}/${id}/companies`),
  addAvatar: (id, formData) => api.post(`avatar?type=${resource}&id=${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
};
