// import mime from 'mime-types';
import helper from '../helpers/api';
// import api from '../configs/integration';

const resource = 'files';

export default {

  ...helper(resource, 'integration'),
  // getUrl: id => api.get(`${resource}/download?id=${id}`),
  // download: data => {

  // const oReq = new XMLHttpRequest();
  // // The Endpoint of your server
  // const URLToPDF = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/172905/test.pdf';
  // // Configure XMLHttpRequest
  // oReq.open('GET', URLToPDF, true);
  // // Important to use the blob response type
  // oReq.responseType = 'blob';
  // // When the file request finishes
  // // Is up to you, the configuration for error events etc.
  // oReq.onload = function () {
  //   // Once the file is downloaded, open a new window with the PDF
  //   // Remember to allow the POP-UPS in your browser
  //   const file = new Blob([oReq.response], {
  //     type: mime.lookup(data.name),
  //   });
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(file);
  //   link.target = '_blank';
  //   link.download = data.name;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  // oReq.send();
  // },
};
