import { view } from '../../constants/localStorageKey';
import apiFactory from '../../factories/api';

const userAPI = apiFactory.get('user');

export default (state, action) => {
  switch (action.type) {
    case 'CHANGE_VIEW': {
      localStorage[view] = JSON.stringify(action.payload);

      return action.payload;
    }

    case 'CHANGE_TO_UPDATED_VIEW': {
      let updatedView = localStorage['collicare-updated-view'];

      if (updatedView) {
        updatedView = JSON.parse(updatedView);
        const user = localStorage['collicare-user-details']
          ? JSON.parse(localStorage['collicare-user-details'])
          : {};
        const id = user.details ? user.details.id : null;

        (async () => {
          if (id) {
            try {
              await userAPI.changeViewDetails(id, {
                view: updatedView,
              });
            } catch (error) {
              console.log(error);
            }
          }
        })();

        localStorage[view] = JSON.stringify(updatedView);
        localStorage.removeItem('collicare-updated-view');
      }

      return updatedView || JSON.parse(localStorage[view]);
    }

    default:
      return state;
  }
};
