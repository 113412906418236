import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const FBContext = createContext();

export const FileBookingProvider = ({ reducer, children }) => (
  <FBContext.Provider value={useReducer(reducer, 0)}>
    { children }
  </FBContext.Provider>
);

FileBookingProvider.propTypes = {
  reducer: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useStateValue = () => useContext(FBContext);
