import helper from '../helpers/api';
import api from '../configs/integration';

const resource = 'claims';

export default {
  ...helper(resource, 'integration'),
  addAttachment: formData => api.post('upload-file', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }),
  sendClaim: data => api.post('claims', data),
};

