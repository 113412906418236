import api from '../configs/api';
import integration from '../configs/integration';

const serialize = (obj) => {
  if (!obj) return '';
  const str = [];
  Object.keys(obj).forEach(item => {
    if (obj[item]) str.push(`${encodeURIComponent(item)}=${encodeURIComponent(obj[item])}`);
  });
  return `?${str.join('&')}`;
};

const types = {
  api,
  integration,
};

export default (resource, type = 'api', params = {}) => ({
  browse: (queryParams = null, headers = {}) => types[type].get(resource + serialize(queryParams && queryParams.only ? queryParams : { ...queryParams, ...params }), headers),
  find: (id, headers = {}) => types[type].get(`${resource}/${id}`, headers),
  create: (payload, headers = {}) => types[type].post(resource, payload, headers),
  update: (payload, headers = {}) => types[type].put(`${resource}/${payload.id}`, payload, headers),
  delete: (id, headers = {}) => types[type].delete(`${resource}/${id}`, headers),
});
