import React, { Fragment, Suspense, useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import { useStateValue } from '../../contexts/Auth';
import { ViewProvider } from '../../contexts/View';
import viewReducer from '../../contexts/reducers/view';
import { view as viewKey } from '../../constants/localStorageKey';
import menu from '../../constants/menu';
import { standard } from '../../constants/views';
import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';

// components
import CCHeader from '../../components/CCHeader';
import CCNav from '../../components/CCNav';
import NoMatch from '../../pages/NoMatch';

const Router = () => {
  const [{ authenticated, details }] = useStateValue();
  const role = details.role || 0;

  const handlePrivateRoute = () => {
    if (role === 1) {
      return menu.admin.map(({ component, path }) => (
        <PrivateRoute
          key={uuidv4()}
          role={role}
          hasAccess={role === 1}
          authenticated={authenticated}
          component={component}
          path={path}
        />
      ));
    }
    if (role === 2 || role === 3) {
      return menu.users.map(({ component, path }) => (
        <PrivateRoute
          key={uuidv4()}
          role={role}
          hasAccess={role === 2 || role === 3}
          authenticated={authenticated}
          component={component}
          path={path}
        />
      ));
    }
    if (role === 4) {
      return menu.bookingShipmentRole.map(({ component, path }) => (
        <PrivateRoute
          key={uuidv4()}
          role={role}
          hasAccess={role === 4}
          authenticated={authenticated}
          component={component}
          path={path}
        />
      ));
    }
    if (role === 5) {
      return menu.bookingRole.map(({ component, path }) => (
        <PrivateRoute
          key={uuidv4()}
          role={role}
          hasAccess={role === 5}
          authenticated={authenticated}
          component={component}
          path={path}
        />
      ));
    }
  };

  const handleRedirect = () => {
    if (role === 1) return '/overview';
    if (role === 2 || role === 3 || role === 5) return '/dashboard';
    if (role === 4) return '/bookings/create';
    return '/';
  };

  useEffect(() => {
    if (details && details.company && details.company.chat === 'N/A') {
      document.getElementById('collicare-chat').style.display = 'none';
    } else {
      document.getElementById('collicare-chat').style.display = 'flex';
    }
  }, [details]);

  return (
    <BrowserRouter
      onUpdate={() => window.scrollTo(0, 0)}
    >
      <ScrollToTop>
        <Fragment>
          {
            authenticated
                && (
                <div className="top-container">
                  <CCHeader
                    name={`${details.first_name} ${details.last_name}`}
                    role={role}
                  />
                  <CCNav role={role} />
                </div>
                )
          }
          <ViewProvider
            initialState={
              authenticated && localStorage[viewKey]
                ? JSON.parse(localStorage[viewKey])
                : standard
            }
            reducer={viewReducer}
          >
            <Suspense fallback={(
              <div className="full-size">
                <div className="lds-ripple"><div /><div /></div>
              </div>
            )}
            >
              <Switch>
                {
                  menu.notAuthenticated.map(({ component: Component, path }) => (
                    <Route
                      exact
                      key={uuidv4()}
                      path={path}
                      render={() => (
                        !authenticated
                          ? <Component />
                          : <Redirect to={handleRedirect()} />
                      )}
                    />
                  ))
                }
                {handlePrivateRoute()}
                <Route component={NoMatch} />
              </Switch>
            </Suspense>
          </ViewProvider>
        </Fragment>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Router;
