import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const NotificationsContext = createContext();

export const NotificationsProvider = ({ reducer, children }) => (
  <NotificationsContext.Provider value={useReducer(reducer, [])}>
    { children }
  </NotificationsContext.Provider>
);

NotificationsProvider.propTypes = {
  reducer: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useStateValue = () => useContext(NotificationsContext);
