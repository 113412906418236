export default (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      return { form: action.payload };
    }

    default:
      return state;
  }
};

