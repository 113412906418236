import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export const AuthProvider = ({ initialState, reducer, children }) => (
  <AuthContext.Provider value={useReducer(reducer, initialState)}>
    { children }
  </AuthContext.Provider>
);

AuthProvider.propTypes = {
  initialState: PropTypes.instanceOf(Object).isRequired,
  reducer: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useStateValue = () => useContext(AuthContext);
