import helper from '../helpers/api';
import api from '../configs/api';
import { CMRLabelDate } from '../helpers/utils';

const resource = 'booking';

export default {
  ...helper(resource),
  getCreatedBookings: () => api.get(`${resource}`),
  getCmr: async (id, data) => {
    const response = await api.get(`${resource}/${id}/cmr`, { responseType: 'blob' });
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    if (process.browser) {
      const anchorTag = document.createElement('a');
      anchorTag.href = url;
      anchorTag.download = `ecare_cmr_${data.id}_${CMRLabelDate(data.created_at)}.pdf`;
      anchorTag.click();
    }
  },
  getLabel: async (id, data) => {
    const response = await api.get(`${resource}/${id}/ean-label`, { responseType: 'blob' });
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    if (process.browser) {
      const anchorTag = document.createElement('a');
      anchorTag.href = url;
      anchorTag.download = `ecare_label_${data.id}_${CMRLabelDate(data.created_at)}.pdf`;
      anchorTag.click();
    }
  },
  previewPdf: async (id, type, token) => {
    const response = await api.get(`${resource}/${id}/${type}?token=${token}&preview=true`, { responseType: 'blob' });
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    if (process.browser) {
      const anchorTag = document.createElement('a');
      anchorTag.href = url;
      anchorTag.target = '_blank';
      anchorTag.click();
    }
  },
};
