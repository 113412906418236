import helper from '../helpers/api';
import api from '../configs/integration';

const resource = 'invoices';
const params = {
  fields: 'id,dbid,currency,amount,customer_name,invoice_date,due_date,amount_incl_tax',
};

export default {
  ...helper(resource, 'integration', params),
  export: (exportType, fields, filter, sort) => api.get(`${resource}?export=${exportType}&fields=${fields}${filter}${sort && sort.length ? `&sort=${sort[0].key}:${sort[0].mode}` : ''}`),
};
